import "iconify-icon";
import Swiper, {
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Thumbs,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "aos/dist/aos.css";
import "flowbite";
import Swal from "sweetalert2";
import $ from "jquery";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Datepicker from "flowbite-datepicker/Datepicker";
import tr from "flowbite-datepicker/locales/tr";

import "../../../node_modules/jquery-mask-plugin/dist/jquery.mask.min";

import moment from "moment";
// import 'flowbite';
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import select2 from "select2";
import "select2/dist/css/select2.min.css";
moment.locale("tr");

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();

Object.assign(Datepicker.locales, tr);
document.addEventListener("livewire:load", function () {
  initializeSelect2();
});

document.addEventListener("livewire:update", function () {
  initializeSelect2();
});

function initializeSelect2() {
  $(".phone").mask("0 (000) 000 00 00");

  $(".select22").select2({
    placeholder: "Kurum Seçiniz",
    width: "100%",
  });

  $(".select22").on("change", function (e) {
    var data = $(this).val();
    Livewire.emit("updateCompany", data);
  });
}
$(function () {
  $(".phone").mask("0 (000) 000 00 00");

  $(".select22").select2({
    placeholder: "Kurum Seçinizx",
    width: "100%",
  });

  $(".select22").on("change", function (e) {
    var data = $(this).val();
    console.log(data);
    Livewire.emit("updateCompany", data);
  });

  if ($("#datepicker").length > 0) {
    var nowDate = new Date();
    const datepickerEl = document.getElementById("datepicker");
    var datepickerblock = new Datepicker(datepickerEl, {
      title: moment().format("DD MMMM YYYY"),
      language: "tr",
      format: "yyyy-mm-dd",
    });
  }

  $(".datepicker-cell").on("click", function (e) {
    var date = $(this).data("date");
    var formatDate = moment(date).format("YYYY-MM-DD");
    window.location.href = "?date=" + formatDate;
  });
});

Fancybox.bind("[data-fancybox]", {
  // Your custom options
});

var galleryThumbs = new Swiper(".gallery-thumbs", {
  modules: [Navigation, Pagination, Mousewheel, Keyboard, Thumbs],
  centeredSlides: true,
  centeredSlidesBounds: true,
  direction: "vertical",
  spaceBetween: 0,
  slidesPerView: 3,
  mousewheel: true,
  freeMode: false,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  watchOverflow: true,
  breakpoints: {
    0: {
      direction: "vertical",
      slidesPerView: 3,
    },
    480: {
      direction: "vertical",
      slidesPerView: 3,
    },
  },
});
var galleryTop = new Swiper(".gallery-top", {
  modules: [Navigation, Pagination, Mousewheel, Keyboard, Thumbs],
  direction: "vertical",
  spaceBetween: 10,
  mousewheel: true,
  thumbs: {
    swiper: galleryThumbs,
  },
});
galleryTop.on("slideChangeTransitionStart", function () {
  galleryThumbs.slideTo(galleryTop.activeIndex);
});
galleryThumbs.on("transitionStart", function () {
  galleryTop.slideTo(galleryThumbs.activeIndex);
});

var swiper = new Swiper(".mySwiper", {
  spaceBetween: 30,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const slider = new Swiper(".slider", {
  modules: [Navigation, Pagination],
  a11y: false,
  preventClicks: true,
  paginationClickable: true,
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

const slider2 = new Swiper(".slider2", {
  modules: [Navigation, Pagination],
  a11y: false,
  preventClicks: true,
  paginationClickable: true,
  loop: true,
  navigation: {
    nextEl: ".slider-next2",
    prevEl: ".slider-prev2",
  },
  pagination: {
    el: ".slider22",
    clickable: true,
  },
});
const bottomSwiper = new Swiper(".bottom-swiper", {
  modules: [Navigation, Pagination],
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 50,
    },
    480: {
      slidesPerView: 2,
      spaceBetween: 50,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 50,
    },
    992: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    1500: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
  },
});

const news = new Swiper(".news-slider", {
  modules: [Navigation, Pagination],
  loop: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  spaceBetween: 50,
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
    },
  },
});

$("form#news-form").submit(function () {
  $.post($(this).attr("action"), $(this).serialize())
    .done(function (res) {
      Swal.fire({
        icon: "success",
        title: "Tebrikler!",
        text: res.message,
      });
    })
    .fail(function (xhr, status, error) {
      var obj = JSON.parse(xhr.responseText);
      obj = obj.errors;
      var errors = "";
      $.each(obj, function (key, val) {
        errors += " - " + val + "\n";
      });
      Swal.fire({
        icon: "error",
        title: "Hata!",
        text: errors,
      });
    });
  return false;
});

function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.1)"
    );
  }
  throw new Error("Bad Hex");
}

$(function () {
  $(".tab-btn").each(function (i, k) {
    $(this).css({
      background: $(this).data("bg"),
    });
  });

  if ($(".tab-content").length > 0) {
    var firstDataBg = $(".tab-btn:first-child").data("bg");
    $(".tab-content[data-key=0]").css({
      background: hexToRgbA(firstDataBg),
    });
    $(".tab-btn[data-key=0]").css({
      background: hexToRgbA(firstDataBg),
    });
    $(".tab-btn").on("click", function () {
      var dataBg = $(this).data("bg");
      var dataTextColor = $(this).data("textcolor");
      var dataKey = $(this).data("key");
      $(".tab-btn").each(function (i, k) {
        $(this).css({
          background: $(this).data("bg"),
          color: dataBg,
        });
      });
      $(this).css({
        background: hexToRgbA(dataBg),
      });
      $(".tab-content[data-key=" + dataKey + "]").css({
        background: hexToRgbA(dataBg),
      });
    });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  var links = document.querySelectorAll("ul.breadcrumb li a");
  links.forEach(function (link) {
    var textWithoutParentheses = removeParentheses(link.textContent.trim());
    var words = textWithoutParentheses.split(" ");
    var capitalizedText = words
      .map(function (word) {
        return capitalizeFirstLetter(word);
      })
      .join(" ");
    link.textContent = capitalizedText
      .replace("Un Global Compact", "UN Global Compact")
      .replace("İçin", "için")
      .replace("Için", "için")
      .replace("Ve", "ve");
  });
});

function capitalizeFirstLetter(word) {
  // İlk harf büyük yap, geri kalan harfleri küçük yap
  const newWord =
    word.charAt(0).toLocaleUpperCase() + word.slice(1).toLocaleLowerCase();

  return newWord
    .replace("Alanlari", "Alanları")
    .replace("Hakkimizda", "Hakkımızda")
    .replace("Içın", "için")
    .replace("Için", "için")
    .replace("Ve", "ve")
    .replace("Ska", "SKA");
}

function removeParentheses(text) {
  if (text !== "Raporlama (CoP)") return text.replace(/\([^)]*\)/g, "").trim();
}

document.addEventListener("DOMContentLoaded", function () {
  $(".slider-prev2").removeClass("swiper-button-lock");
  $(".slider-next2").removeClass("swiper-button-lock");
  $(".slider .swiper-button-next").removeClass("swiper-button-lock");
  $(".slider .swiper-button-prev").removeClass("swiper-button-lock");

  var iframe = document.getElementById("iframe_video");

  if (iframe) {
    iframe.onload = function () {
      var iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      var videos = iframeDocument.getElementsByTagName("video");
      for (var i = 0; i < videos.length; i++) {
        videos[i].removeAttribute("autoplay");
        videos[i].pause();
      }
    };
  } else {
    console.error("iframe_video ID'li öğe bulunamadı!");
  }
});
